<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/bg.png'); 
        height: 80vh; 
        min-height: 100vh;
        background-size: cover; 
        background-attachment: fixed; 
        overflow-y: scroll;">
       <div class="form-group pt-4 d-flex justify-content-between px-2" style="width: 100%">
          <div>
            <h3 class="text-white pl-3 pt-1">
              {{ $route.query.provider }}
            </h3>
          </div>
            <div>
                <input v-model="name" type="text" class="form-control game-search" placeholder="Search Game Name">
            </div>
            <!-- <div class="provider-dropdown">
                <DropdownMenu>
                    <DropdownItem v-model="provider" :options="option1" />
                </DropdownMenu>
            </div> -->
        </div>
        <div class="p-0 pb-5 px-2" style="width: 100%; gap: 8px; display: grid; grid-template-columns: 1fr 1fr 1fr;">
            <div
                class="game-card p-0"
                v-show="game.provider !== 'Blueprint'"
                v-for="(game, index) in newGames.length > 0 ? newGames : games"
                :key="index"
                 @click="showAlertBox(game)"
            >
              <img
                    :src="imageLinkGenerate(game.gameID)"
                    alt=""
                    style="width: 100%; height: 100%; border-radius: 5px"
                />
                <!-- <span class="" style="color: #fff; text-align: center; padding: 3px 3px 3px 3px; text-overflow: ellipsis; overflow: hidden; width: 100%; white-space: nowrap; ">{{game.gameName}}</span> -->
            </div>
        </div>

        <div class="col-4">
          <div
              class="modal fade"
              id="limit"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modal-form"
              @click="hideLimit()"
          >
              <div
                  class="modal-dialog modal- modal-dialog-centered"
                  role="document"
              >
                  <div class="modal-content">
                      <div class="modal-body my-5 mx-3 text-center">
                        <span class="text-danger text-lg">
                          <b>အထူးသတိပြုရန်။</b> <br><br>
                        </span>
                      <span>{{limitText}}</span>
                      <br><br> <span class="text-dark text-sm">
                          <button class="btn btn-danger" @click="gameInit(desiredGame)">
                          {{
                              $store.state.language === "en"
                                  ? "Play The Game"
                                  : "ဂိမ်းဆော့မည်။"
                          }}
                          </button>
                      </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        
        <van-overlay :show="isLoading">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import { mapActions } from 'vuex';

export default {
//   components: { DropdownMenu, DropdownItem },

  data() {
    return {
      isLoading: false,
      games: [],
      rows: 0,
      provider: 'Pragmatic',
      name: null,
      newGames: [],
      option1: [
        // { text: 'All', value: 'all' },
        { text: 'Pragmatic Play', value: 'Pragmatic' },
        { text: 'Jili', value: 'Jili' },
        { text: 'Play Star', value: 'Playstar' },
        { text: 'Fast Spin', value: 'Fastspin' },
        { text: 'Spade Gaming', value: 'Spade' },
      ],
      desiredGame: null,
      limitText: '',
    };
  },
  methods: {

    alertBoxForLimit(){
      $('#balanceLimit').modal('show');
    },
    ...mapActions(['fetchUser']),
    imageLinkGenerate(gameId) {
      // if (this.$route.query.provider === 'Jili') {
      //   return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/jili/GAMEID_${gameId}_EN.png`;
      // } if (this.$route.query.provider === 'Spade') {
      //   return `https://api-egame-staging.sgplay.net/thumbnail/en_US/${gameId}.jpg`;
      // } if (this.$route.query.provider === 'Fastspin') {
      //   return `http://api-egame-staging.fsuat.com/thumbnail/en_US/${gameId}.jpg`;
      // } if (this.$route.query.provider === 'PGSoft') {
      //   return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/pgsoft/pgsoft_${gameId}.png`;
      // } if (this.$route.query.provider === 'Playstar') {
      //   return `/build/assets/img/playstar/${gameId}.png`;
      // } if (this.$route.query.provider === 'FaChai') {
      //   return `https://agent-icon.fcg1688.net/icon/${gameId}_200x200_en.png`;
      // }
      // if (this.$route.query.provider === 'Live22') {
      //   return `https://l22gth.l22play.com/thumbs/web/${gameId}.png`;
      // }
      // return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/pp/${gameId}.png`;
      if (this.$route.query.provider === 'Jili') {
        //   return `https://hot.kingroman999.com/gameIcon3/apiJili/en/JiLi_${gameId}.png`;
        return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/jili/${gameId}.webp`;
      } if (this.$route.query.provider === 'Spade') {
        //   return `https://hot.onexslot.net/gameIcon3/apiSpadeGame/en/${gameId}.png`;
        // return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/spade/${gameId}.webp`;
        return `https://merchantapi.silverkirin88.com/thumbnail/en_US/${gameId}.jpg`;
      } if (this.$route.query.provider === 'Fastspin') {
          return `http://api-egame-staging.fsuat.com/thumbnail/en_US/${gameId}.jpg`;
      } if (this.$route.query.provider === 'Playstar') {
          return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/playstar/${gameId}.png`;
      } if (this.$route.query.provider === 'FaChai') {
        //   return `https://hot.kingroman999.com/gameIcon3/apiFc/en/fc_${gameId}.png`;
          return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/fachai/${gameId}.webp`;
      } if (this.$route.query.provider === 'PGSoft') {
        //   return `https://hot.kingroman999.com/gameIcon3/apiPg/en/${gameId}.png`;
        // return `https://hot.kingroman999.com/gameIcon3/apiPg/en/${gameId}.png`;
        return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/pgsoft/${gameId}.webp`;
      } if (this.$route.query.provider === 'Joker') {
        //   return `https://hot.kingroman999.com/gameIcon3/apiJoker/en/${gameId}.png`;
        return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/joker/${gameId}.webp`;
      } if (this.$route.query.provider === '5G') {
          return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/5g/${gameId}.png`;
      } if (this.$route.query.provider === 'KA') {
        //   return `https://hot.kingroman999.com/gameIcon3/apiKa/en/${gameId}.png`;
        return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/ka/${gameId}.webp`;
      } if (this.$route.query.provider === 'AceWin') {
          return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/acewin/${gameId}.png`;
      } if (this.$route.query.provider === 'JDB') {
        //   return `https://hot.onexslot.net/gameIcon3/apiJdb/en/0_${gameId}.png`;
        return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/jdb/${gameId}.webp`;
      }

      // return `https://api-2133.ppgames.net/game_pic/rec/325/${gameId}.png`;
    //   return `https://hot.kingroman999.com/gameIcon3/apiPp/en/${gameId}.png`;
      return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/slot-images/pp/${gameId}.webp`;
      // return `https://casinogamesk8.imgix.net/live/image/imgix/game/vs20sugrux-en-US_ESHJ231102023046.png?auto=format&q=60`;
    },
    detectMob() {
      let check = false;
      /* eslint-disable */
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          )
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                      a.substr(0, 4),
                    )
        ) { check = true; }
      }(navigator.userAgent || navigator.vendor || window.opera));
      return check;
    },
    async fetchGames() {
      try {
        const res = await axios.get('/games', {
          params: {
            // page: 1,
            // limit: this.provider === 'all' ? 200 : 10000,
            // is_mobile: this.detectMob(),
            // type: this.gameType,
            provider: this.$route.query.provider,
          },
        });

        // const result = JSON.parse(res.data.data);
        // this.rows = result['_meta'].pageCount =
        // console.log('result', result);
        this.games = res.data.data;

        this.games = this.games.filter((game) => game.gameTypeID.includes('vs'));
      } catch (error) {
        console.log(error);
      }
    },

    showAlertBox(game) {
      if(this.$store.state.authUser.game_balance < 1000) {
        this.alertBoxForLimit();
      } else if(this.$route.query.provider === 'PGSoft'){
         this.limitText = 'PG Soft တွင် 1K unit သည် 1,000 ကျပ်နှင့်ညီမျပါသည်။';
         this.desiredGame = game;
         $('#limit').modal('show');
        }
      else if(this.$route.query.provider === 'FaChai') {
        this.limitText = 'FaChai Provider တွင် 1 unit သည် 100 ကျပ်နှင့်ညီမျပါသည်။';
        this.desiredGame = game;
        $('#limit').modal('show');
      } else {
        this.gameInit(game);
      }
    },

    async gameInit(game) {
      try {
        this.isLoading = true;
        $('#limit').modal('hide');
        const res = await axios.get('/games/url?gameID='+game.gameID+'&provider='+this.$route.query.provider, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // if(this.$route.query.provider === 'PGSoft'){
        //   const newPage = window.open('', '_self');

        //   // Write HTML content into the new window
        //   if (newPage) {
        //     newPage.document.open();
        //     newPage.document.write(res.data.data.gameUrl);
        //     newPage.document.close();
        //   }
        // } else {
          window.open(res.data.data.gameUrl, "_self");
        // }
        setTimeout(() => {
            this.isLoading = false;
          }, 1000);



        // if (newWindow) {
        //   setTimeout(() => {
        //     this.isLoading = false;
        //   }, 1000);
        // } else {
        //   this.isLoading = true;
        // }
      } catch (error) {
        this.isLoading = false;
        let errorMessage = 'ဤ အခန်းကိုဆော့လို့ မရသေးပါရှင့်။';
        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        }

        Dialog.alert({
            message: errorMessage,
            confirmButtonText:
                            this.$store.state.language === 'en'
                                ? 'Go Back'
                                : 'နောက်သို့ပြန်သွားမည်။',
            })
      }
    },
  },
  watch: {
    provider() {
        this.fetchGames();
    //   this.newGames = this.games.filter((game) => game.provider.includes(this.provider));
    },
    name() {
      this.newGames = this.games.filter((game) => game.gameName.toLowerCase().includes(this.name.toLowerCase()));
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    this.fetchGames();
    // if (!this.$auth.user().is_verified) {
    //   this.$router.push('/auth/verify');
    // }
  },
};
</script>
<style scoped>
.provider-dropdown {
    width: 200px;
    height: 40px;
}

.game-search {
    width: 230px !important;
    height: 40px;
    border: 1px solid #323743;
    background: transparent;
    box-shadow: none;
    color: white;
}

/* .game-search:active, .game-search:focus {
    border: 1px solid #000 !important;
} */

.game-card {
    /* background-color: rgba(255,255,255,0.2) !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    flex: 1 1 calc(33% - 10px);
    box-sizing: border-box;
    border-radius: 5px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
