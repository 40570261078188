<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8215.png'); min-height: 100vh; height: auto; background-size: cover; padding-top: 10px;">
        <div class="col-12 account-container-wrapper d-flex py-1">
            <div class="col-lg-6 col-5 my-auto">
            <img :src="$store.state.authUser.gender === 'female' ? 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1b5a569c-c421-446c-86a8-98da438fbf00/public' : 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bc293e0-e453-442c-3dcd-a3572fece800/public'" style="width: auto; height: 100px; border-radius: 20%;" alt="">
            </div>

            <div class="col-lg-6 col-7 p-0">
            <div class="pt-2">
                <div class="d-flex">
                <p class="css-17h40hp mb-1" style="width: 85px;"> 
                            {{
                                $store.state.language === "en"
                                    ? "Username"
                                    : "အမည်"
                            }}</p>
                <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{$store.state.authUser.name}} </p>
                </div>
                <div class="d-flex">
                <p class="css-17h40hp mb-1" style="width: 85px">
                            {{
                                $store.state.language === "en"
                                    ? "Balance"
                                    : "လက်ကျန်ငွေ"
                            }}</p>
                <p class="css-eurmd1 mb-1" style="font-weight: 400;">:
                    <b>{{ Number($store.state.amount).toLocaleString() }}</b>
                {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
                </div>
                <div class="d-flex">
                <p class="css-17h40hp mb-1" style="width: 85px">
                            {{
                                $store.state.language === "en"
                                    ? "Phone"
                                    : "ဖုန်းနံပါတ်"
                            }}</p>
                <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
                </div>
                <div class="d-flex" v-if="$store.state.authUser.commissioner">
                <p class="css-17h40hp mb-1 text-nowrap" style="width: 85px">
                            {{
                                $store.state.language === "en"
                                    ? "Agent Referral"
                                    : "အေးဂျင့်ကုဒ်"
                            }}</p>
                <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.commissioner }}</p>
                </div>
            </div>
            </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap px-3 py-3">
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%9C%E1%80%B0%E1%80%9E%E1%80%85%E1%80%BA.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(0) : fetchBugyee(0)">
            </div>
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%82%E1%80%BB%E1%80%B0%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(1) : fetchBugyee(1)">
            </div>
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%85%E1%80%AE%E1%80%94%E1%80%AE%E1%80%9A%E1%80%AC.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(2) : fetchBugyee(2)">
            </div>
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%B1%E1%80%AC%E1%80%B7%E1%80%85%E1%80%BA.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(3) : fetchBugyee(3)">
            </div>
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%99%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(4) : fetchBugyee(4)">
            </div>
            <div class="col-6 px-0">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/%E1%80%98%E1%80%AE%E1%80%9C%E1%80%AE%E1%80%9A%E1%80%B6%E1%80%94%E1%80%AC.png" alt="" width="100%" @click="$route.query.t === 'skm' ? fetchShanKoMeeData(5) : fetchBugyee(5)">
            </div>
        </div>
        
        <van-overlay :show="isLoading">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>

        <div class="col-4">
            <div
                class="modal fade"
                id="limit"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                @click="hideLimit()"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body my-5 mx-3 text-center">
                        <span class="text-danger text-lg">
                            <b>အထူးသတိပြုရန်။</b> <br><br>
                        </span>
                        <span>{{limitText}}</span>
                        <br><br> <span class="text-dark text-sm">
                            <!-- <button class="btn btn-danger" @click="gameInit(desiredGame)">
                            {{
                                $store.state.language === "en"
                                    ? "Play The Game"
                                    : "ဂိမ်းဆော့မည်။"
                            }}
                            </button> -->
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
// import { DropdownMenu, DropdownItem } from 'vant';
import { mapActions } from 'vuex';

export default {
//   components: { DropdownMenu, DropdownItem },

  data() {
    return {
      isLoading: false,
      limitText: '',
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    hideLimit() {
        $('#limit').modal('hide');
    },

    async fetchBugyee(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
            const res = await axios.get('/bugyee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // console.log(res.data)
            window.location.assign(res.data.url)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchShanKoMeeData(level = null) {
      try {
        this.isLoading = true;
        const authUser = await this.fetchUser();
        if (!authUser) {
          this.$router.push('/auth/login');
        }
        
        const amount = authUser.amount;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 0 && amount > 100000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount > 300000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount > 500000){
            this.limitText = "သတ်မှတ်ထားသော ငွေပမာဏထပ် ကျော်လွန်နေပါသည်။ သင့်လျော်သောအခန်းတွင်သာ ကစားပေးပါ။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }

        // if(this.nickname && this.nickname !== ''){
            const res = await axios.get('/shankomee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            });
            // console.log(res.data)
            window.location.assign(res.data.url)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
  },
};
</script>
<style scoped>
.account-container-wrapper {
      width: auto;
      height: auto;
      border-radius: 10px;
      padding-left: 4px;
      padding-right: 4px;
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 10px;
      color: white;
      background-color: rgba(255,255,255,0.2) !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .top-wrapper,
    .bottom-wrapper {
      padding: 10px;
      width: 100%;
      display: flex;
    }
    .top-right,
    .bottom-right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: end;
    }
    .top-left,
    .bottom-left {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: start;
    }

.provider-dropdown {
    width: 200px;
    height: 40px;
}

.game-search {
    width: 230px !important;
    height: 40px;
    border: 1px solid white;
    background: transparent;
    color: white;
}

/* .game-search:active, .game-search:focus {
    border: 1px solid #000 !important;
} */

.game-card {
    background-color: rgba(255,255,255,0.2) !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    flex: 1 1 calc(33% - 10px);
    box-sizing: border-box;
    border-radius: 5px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
