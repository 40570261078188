<template>
    <div style="
        background: #121A35;
        background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/bg.png'); 
        height: 80vh; 
        min-height: 100vh;
        background-size: cover; 
        background-attachment: fixed; 
        overflow-y: scroll;
    ">
        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="text"
            v-if="text"
        />
        <Sticky class="normal-box pt-3 px-2">
      <div style="background: #242946; border-radius: 5px;" class="col-12 mx-auto py-3">
          <div class="d-flex justify-content-between">
              <div class="my-auto">
                <b class="text-sm text-nowrap">
                    <i class="fas fa-wallet my-auto mr-1" style="color: #93a1c0; font-size: 1.2rem;"></i> :
                    <span class="text-white">
                      {{
                        Number($store.state.amount).toLocaleString()
                      }} {{ $store.state.language === "en" ? "MMK" : "ကျပ်" }}
                    </span>
                </b>
              </div>
              <div class="col-3 p-0">
                <button class="btn mx-3" style="background: #93a1c0; color: black;" @click="$router.push('/user/dream-books?type=3d')">
                {{
                    $store.state.language === "en"
                        ? "Dream"
                        : "အိမ်မက်"
                }}
                </button>
              </div>
              <div class="my-auto">
                  <b class="text-sm text-white">{{ $store.state.language === "en" ? "Bet Close" : "ထီပိတ်ချိန်" }} : </b> <span class="text-sm text-white">{{ closeFormattedDate(threed.draw_date) }}, {{ threed.latest_bet }} PM</span>
              </div>
          </div>
          <hr class="mt-2 mb-3">
          <div class="d-flex justify-content-between">
              
              <!-- <div class="col-3 p-0">
                <button class="btn btn-dark mx-3" @click="$router.push('/user/dream-books?type=3d')">
                {{
                    $store.state.language === "en"
                        ? "Dream"
                        : "အိမ်မက်"
                }}
                </button>
              </div> -->
              <div class="col-6 p-0">
                  <!-- {{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }} -->
                  <div class="form-group mb-0">
                          <input
                              type="number"
                              ref="amount"
                              v-model="numberAmount"
                              class="form-control amount"
                              placeholder="100.00 MMK"
                              style="height: 35px;"
                          />
                  </div>
              </div>

              <div class="d-flex">
                    <span class="px-2 my-auto py-1 number-reverse" :class="isReverse ? 'active' : ''" @click="isReverse = !isReverse">R</span>
              </div>

              <div class="col-4 p-0 pl-2">
                  <button class="btn btn-success" style="background: #FAE63C; border: none; width: 100%; height: 35px; color: black;"  @click="preBet">
                      <div>
                          {{
                              $store.state.language === "en"
                                  ? "Bet"
                                  : "ထိုးမည်"
                          }}
                      </div>
                  </button>
              </div>

          </div>

          <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex justify-content-start px-0">
                    <span class="text-white text-sm font-weight-600 mr-2" @click="openColorsModal()">{{ $store.state.language === "en" ? "Meaning of Color!" : "အရောင်များ၏ အဓိပ္ပာယ်။" }}</span>
                    <span class="badge badge-dark text-white" style="background: #25388D"><i class="fa fa-info" @click="openColorsModal()"></i> </span>
              </div>
              <div class="col-6 d-flex justify-content-end px-0">
                <i class="fa fa-redo my-auto text-lg" style="color: #FAE63C" @click="removeHistory()"></i>
                <span class="my-auto text-white text-sm font-weight-600 ml-2" @click="removeHistory()">{{ $store.state.language === "en" ? "All Clear!" : "အားလုံးရှင်း။" }}</span>
              </div>
          </div>
      </div>
    </Sticky>
    <div class="mt-2 mx-2" style="width: 97%; gap: 8px; display: grid; grid-template-columns: 1fr 1fr 1fr;">
        <div class="number-paginate px-4 py-1" :class="tableData === 1 ? 'active' : ''" @click="tableData = 1">000 - 099</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 2 ? 'active' : ''" @click="tableData = 2">100 - 199</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 3 ? 'active' : ''" @click="tableData = 3">200 - 299</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 4 ? 'active' : ''" @click="tableData = 4">300 - 399</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 5 ? 'active' : ''" @click="tableData = 5">400 - 499</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 6 ? 'active' : ''" @click="tableData = 6">500 - 599</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 7 ? 'active' : ''" @click="tableData = 7">600 - 699</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 8 ? 'active' : ''" @click="tableData = 8">700 - 799</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 9 ? 'active' : ''" @click="tableData = 9">800 - 899</div>
        <div class="number-paginate px-4 py-1" :class="tableData === 10 ? 'active' : ''" @click="tableData = 10">900 - 999</div>
    </div>

        <div class="d-flex p-0 flex-wrap main-wrapper" style="margin-top: -1.4rem;">
            <div class="col-lg-4 col-md-6 px-1 boxes">
                <Table @numbers="getNumbers" :isTwod="false" :allNumbers="allNumbers" :tableData="tableData" :on-my-event="myEventSource"/>
            </div>
            <div class="col-lg-4 col-md-6 col-12 px-2 boxes mb-0">
              <!-- <NormalBox @numbers="getNumbers" type="threed" :allNumbers="$store.state.threed_numbers"/> -->
            </div>
            <div class="col-lg-4 col-md-6 px-2 boxes pb-5" style="margin-bottom: 5rem !important">
                <!-- <ThreedGroupBox @numbers="getNumbers" /> -->
            </div>
            <div class="col-lg-4 col-md-6 px-2">
                <PreviewVoucher
                    :datas="datas"
                    @remove="removeHistory"
                    class="preview-box"
                    :isTwod="false"
                    type="normal"
                    v-if="!isMobile"
                />
                <div class="row">
                    <!-- === only amount === -->
                    <div class="col-md-4 cart-voucher">
                        <div
                            class="modal fade"
                            id="mobile-voucher"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modal-form"
                            aria-hidden="true"
                        >
                            <div
                                class="
                                    modal-dialog
                                    modal-
                                    modal-dialog-centered
                                    modal-sm
                                "
                                role="document"
                            >
                                <div class="modal-content">
                                    <div
                                        class="text-center modal-cross"
                                        @click="closeModal"
                                    >
                                        X
                                    </div>
                                    <div class="modal-body p-0">
                                        <PreviewVoucher
                                            :datas="datas"
                                            @remove="removeHistory"
                                            :isTwod="false"
                                            v-if="isMobile"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 px-2">
            <div class="row">
                <!-- === only amount === -->
                <div class="col-md-4 cart-voucher">
                    <div
                        class="modal fade"
                        id="colors-modal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="modal-form"
                        aria-hidden="true"
                    >
                        <div
                            class="
                                modal-dialog
                                modal-
                                modal-dialog-centered
                                modal-sm
                            "
                            role="document"
                        >
                            <div class="modal-content">
                                <div class="modal-heading bg-dark py-3" style="border-top-right-radius:0.4375rem; border-top-left-radius:0.4375rem">
                                      <h4 class="text-center text-white">
                                        အရောင် အဓိပ္ပါယ် <br> 100% ဖြစ်သောအခါ ထီနံပါတ်များကို ပိတ်ပါမည်။
                                      </h4>
                                    </div>
                                <div class="modal-body px-5 d-flex py-3">
                                    <ul>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: green; width:20px; height: 20px; border-radius: 50%;"></div> 50% အောက်
                                        </div>
                                      </li>
                                      <li class="my-2"> 
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: yellow; width:20px; height: 20px; border-radius: 50%;"></div> 50% မှ 75% အတွင်း 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: red; width:20px; height: 20px; border-radius: 50%;"></div> 90% အထက် 
                                        </div>
                                      </li>
                                      <li class="my-2">
                                        <div class="d-flex">
                                          <div class="my-auto mx-2" style="border:1px solid #d5d5d5; background-color: #7c7676; width:20px; height: 20px; border-radius: 50%;"></div> အပြည့် (100%)
                                        </div>
                                      </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import { newEventSource } from 'vue-parent-emit';
import { NoticeBar, Sticky, Dialog } from 'vant';
import PreviewVoucher from '../../components/PreviewVoucher.vue';
import Table from '../../components/Table.vue';
import ThreedGroupBox from '../../components/ThreedGroupBox.vue';
import NormalBox from '../../components/NormalBox.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    PreviewVoucher,
    ThreedGroupBox,
    NoticeBar,
    Table,
    NormalBox,
    Sticky
  },
  data() {
    return {
      tableData: 1,
      myEventSource: newEventSource(),
      text: '',
      datas: [],
      selected_data: {},
      isMobile: false,
      numberAmount: null,
      isReverse: false,
      threed: {},
      allNumbers: [],
      voucherLength: 0,
    };
  },
  methods: {
    openColorsModal() {
      $('#colors-modal').modal('show');
    },
    closeFormattedDate(draw) {
      return moment(draw).format('MMM Do');
    },
    ...mapActions(['fetchUser']),
    slipId() {
      return this.voucherLength;
    },
    async checkCurrentVouchers() {
      try {
        const res = await axios.post('/vouchers/check', {
          type: 'threed',
        },{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.voucherLength = res.data.voucherLength;
      } catch (error) {
        console.log(error);
      }
    },
    getPermutations(str) {
      if (str.length <= 1) return [str];
      
      const permutations = [];
      
      for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const remainingChars = str.slice(0, i) + str.slice(i + 1);
        for (const permutation of this.getPermutations(remainingChars)) {
          permutations.push(char + permutation);
        }
      }

      return permutations;
    },
    dialog(text) {
            Dialog.alert({
                message: text,
                confirmButtonText:
                    this.$store.state.language === "en"
                        ? "Go Back"
                        : "နောက်သို့ပြန်သွားမည်။",
            }).then(() => {
                // on close
            });
        },
    async preBet() {
    try {
      if (this.isBet) {
          return false;
      }
      if (this.voucherId === this.slipId()) {
          return false;
      }
      if (this.datas.length == 0) {
          const dataError = this.$store.state.language === "en"
                            ? "Please pick some numbers!"
                            : "နံပါတ်အချို့ ရွေးပေးပါ။";
          this.dialog(dataError);
          return false;
      }
      if (!this.numberAmount) {
          const dataError = this.$store.state.language === "en" ? 'Please enter amount' : 'ငွေပမာဏထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      if (this.numberAmount < 100) {
          this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';

          const dataError = this.$store.state.language === "en" ? 'Please enter at least 100 MMK' : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
          this.dialog(dataError);
          return false;
      }
      
      if(this.isReverse){
        // Step 1: Generate permutations for numbers in selected data (datas)
        const allPermutations = this.datas.flatMap(item => {
          const numberStr = String(item.number).padStart(3, '0'); // Pad with leading zeros
          return this.getPermutations(numberStr).map(perm => ({
            ...item,
            number: perm // Use permutation as the new number
          }));
        });

        // Step 2: Filter allNumbers based on permutations
        const filteredFromAllNumbers = this.allNumbers.filter(item =>
          allPermutations.some(permItem => permItem.number === String(item.number).padStart(3, '0'))
        );

        // Step 3: Merge filtered data with selected data (datas)
        const combinedData = [
          ...this.datas,
          ...filteredFromAllNumbers
        ];

        // Step 4: Remove duplicates based on number
        this.datas = Array.from(new Map(combinedData.map(item => [item.number, item])).values());

        // Ensure numbers are handled as strings with consistent padding and ordering
        this.datas.forEach(item => {
          item.number = String(item.number).padStart(3, '0');
        });
      }

      this.datas = this.datas.map(item => ({
            ...item,          // Spread the existing properties of the object
            solo_amount: this.numberAmount,  // Add or update the `solo_amount` property
            draw: this.threed.draw_date
        }));
     
    $("#mobile-voucher").modal("show");
    } catch (error) {
        console.log(error);
    }
  },

  sendEvent() {
    this.myEventSource.emit();
  },

    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    getNumbers(value) {
      this.datas = value;
      this.datas = this.datas.map(item => ({
            ...item,          // Spread the existing properties of the object
            solo_amount: this.numberAmount, // Add or update the `solo_amount` property
            draw: this.threed.draw_date
        }));
    },
    removeHistory() {
      this.datas = [];
      this.fetchClosedNumbers();
      $("#mobile-voucher").modal("hide");
      this.numberAmount = null;
      this.sendEvent();
      this.allNumbers = this.allNumbers.map(item => ({
        ...item,
        isSelect: false
      }));
    },
    async fetchClosedNumbers() {
      try {
        const res = await axios.get('/3d/get_closed_threed_numbers');
        this.text = res.data.data.length > 0
          ? `${
            this.$store.state.language === 'en'
              ? 'Closed number(s)'
              : 'ပိတ်ထားသော နံပါတ်များ'
          } : ${res.data.data.toString()}`
          : null;
      } catch (error) {
        console.log(error);
      }
    },
    async threeData() {
      try {
        const res = await axios.get('3d/data');
        this.threed = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedNumbers() {
      try {
        const res = await axios.post('/threed_numbers', {
          limit: 'all',
        });
       this.allNumbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    
    this.fetchClosedNumbers();
    this.fetchThreedNumbers();
    this.checkCurrentVouchers();
    this.threeData();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    if (this.$auth.check()) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }
  },
};
</script>
<style scoped>
.number-paginate{
    border:2px solid #7988a8;
    border-radius: 5px;
    /* padding: 10px; */
    color: #93a1c0;
    text-align: center;
    /* font-weight: bold; */
    font-size: 1rem;
}
.number-paginate.active{
    background: #25388D;
    color: #93a1c0;
    border: 2px solid #25388D;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
.input-group {
    border: 1px solid #cccccc;
}
.notice-bar {
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
@media (max-width: 768px) {
    .boxes {
        margin-top: 6px;
        margin-bottom: 7px;
    }
    .main-wrapper {
        padding: 0px !important;
    }
}

.number-reverse {
  background: #25388D;
  color: white;
  border-radius: 5px;
  /* font-size: 20px; */
  font-weight: bold;
  cursor: pointer;
}

.number-reverse.active {
  background: #328B93;
  color: white;
}
</style>
