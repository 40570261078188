<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/bg.png'); min-height: 100vh; background-size: cover; padding-bottom: 15rem; background-attachment: fixed; overflow-y: scroll;">
            <div class="sc-tagGq kHYfza">
                <div class="sc-fXSgeo jIuFfr">
                    <!-- <div class="sc-JrDLc kUxSIz text-white text-uppercase text-nowrap">မနက်ပိုင်း</div> -->
                    <p class="sc-fjvvzt eDruqT pt-4" style="font-size: 7rem;">{{lastNumber}}</p>
                </div>
                <div style="font-weight: 400; font-size: 1.3rem; margin-top: -5rem; color: white;">
                    ထီပေါက်သူများ အားလုံးပဲ ဂုဏ်ယူပါတယ်
                </div>
                <!-- <div class="py-1" style="font-weight: 900; font-size: 1.3rem;">
                    Apr 2, 2024, 12:01 PM
                </div> -->

                <div class="sc-dZoequ jiNnzP">
                    <div class="sc-eZkCL jyjJIu mt-4">

                        <!-- THIRD ONE -->
                        <div class="sc-ggpjZQ ljoKKN" style="margin-top: 5rem;">
                            <span
                                class="ant-avatar ant-avatar-circle ant-avatar-image sc-cmaqmh iavbGS css-zl9ks2"
                                style="
                                    width: 56px;
                                    height: 56px;
                                    line-height: 56px;
                                    font-size: 18px;
                                    border: 4px solid #ff004c;
                                "
                                ><img
                                    src="https://myanmarhot2d.com/static/media/default_profile.f06fefecd2a84cdc7274.jpg
"
                            /></span>
                            <div class="sc-kAkpmW AZDY px-3 pt-3 pb-2">
                                <div class="sc-gFVvzn jkjwQW">
                                    <p
                                        class="sc-iMWBiJ fWLnzK"
                                        style="font-weight: 700"
                                    >
                                        {{winnerList[2] ? winnerList[2].name : '-'}}
                                    </p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Bet</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[2] ? Number(winnerList[2].bet).toLocaleString() : '-'}}</p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Win</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[2] ? Number(winnerList[2].won).toLocaleString() : '-'}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- FIRST ONE -->
                        <div class="sc-ggpjZQ ljoKKN mt-5">
                            <span
                                class="ant-avatar ant-avatar-circle ant-avatar-image sc-cmaqmh iavbGS css-zl9ks2"
                                style="
                                    width: 56px;
                                    height: 56px;
                                    line-height: 56px;
                                    font-size: 18px;
                                    border: 4px solid #ff004c;
                                "
                                ><img
                                    src="https://myanmarhot2d.com/static/media/default_profile.f06fefecd2a84cdc7274.jpg
"
                            /></span>
                            <div class="sc-kAkpmW AZDY px-3 pt-3 pb-2">
                                <div class="sc-gFVvzn jkjwQW">
                                    <p
                                        class="sc-iMWBiJ fWLnzK"
                                        style="font-weight: 700"
                                    >
                                        {{winnerList[1] ? winnerList[1].name : ''}}
                                    </p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Bet</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[1] ? Number(winnerList[1].bet).toLocaleString() : '-'}}</p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Win</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[1] ? Number(winnerList[1].won).toLocaleString() : '-'}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- MIDDLE ONE -->
                        <div class="sc-ggpjZQ ljoKKN">
                            <span
                                class="ant-avatar ant-avatar-circle ant-avatar-image sc-cmaqmh iavbGS css-zl9ks2"
                                style="
                                    width: 72px;
                                    height: 72px;
                                    line-height: 72px;
                                    font-size: 18px;
                                        border: 4px solid #ff004c;
                                "
                                ><img
                                    src="https://myanmarhot2d.com/static/media/default_profile.f06fefecd2a84cdc7274.jpg
"
                            /></span>
                            <div class="sc-kAkpmW AZDY px-3 pt-3 pb-2">
                                <div class="sc-gFVvzn jkjwQW">
                                    <p
                                        class="sc-iMWBiJ fWLnzK"
                                        style="font-weight: 700"
                                    >
                                        {{winnerList[0] ? winnerList[0].name : '-'}}
                                    </p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Bet</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[0] ? Number(winnerList[0].bet).toLocaleString() : '-'}}</p>
                                </div>
                                <div class="sc-brPLxw ciObG d-flex justify-content-between" style="width: 100%">
                                    <p class="sc-fvtFIe cZJchM" style="font-weight: 700">Win</p>
                                    <p class="sc-iMWBiJ fWLnzK">{{winnerList[0] ? Number(winnerList[0].won).toLocaleString() : '-'}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="sc-jnOGJG dwtYAJ"></div>
                </div>
                <table style="margin-bottom: 7rem; margin-top: -5rem;">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Bet Amount</th>
                            <th>Won Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in winnerList" :key="index" v-show="index > 2">
                            <td>{{user.name}}</td>
                            <td>{{Number(user.bet).toLocaleString()}} MMK</td>
                            <td>{{Number(user.won).toLocaleString()}} MMK</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</template>

<script>
export default {
  data() {
    return {
      winnerList: [],
      lastNumber: '-',
    };
  },
  methods: {
    async fetchWinnerList() {
      try {
        const res = await axios.get('/2d/winnerlist');
        this.winnerList = res.data.data;
        this.lastNumber = res.data.lastNumber;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchWinnerList();
  },
};
</script>
 <style scoped>
 /* table {
        width: 100%;
        border-collapse: collapse;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    th,
    td {
        padding: 15px;
        background-color: rgba(255,255,255,0.2);
        color: #fff;
    }

    th {
        text-align: left;
    }

    thead {
        th {
            background-color: #55608f;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(255,255,255,0.3);
            }
        }
        td {
            position: relative;
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(255,255,255,0.2);
                    z-index: -1;
                }
            }
        }
    } */

table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    th,
    td {
        padding: 10px !important;
        background-color: rgba(255,255,255,0.2);
        color: #fff;
    }

    th {
        text-align: left;
    }

    thead {
        th {
            background-color: #55608f;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(255,255,255,0.3);
            }
        }
        td {
            position: relative;
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(255,255,255,0.2);
                    z-index: -1;
                }
            }
        }
    }

 .eXUzYM{
        /* background-color: rgb(244, 67, 54); */
    border: 0.5px solid grey;
    width: 100%;
    padding: 9px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
 }
 .kMRvJY {
    color: rgb(92, 92, 92);
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding-top: 4px !important;
}
 .kUxSIz {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px;
    border-radius: 5px;
    background: #ff004c;
    padding: 15px 50px !important;
    width: 162px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
 .cYPtni {
    position: sticky;
    top: 0px;
    z-index: 1000;
    height: 70px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #ff004c;
}
.kHYfza {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    overflow-y: auto;
    padding: 0px 16px 16px;
}

.jIuFfr {
    background-image: url('https://phoenix2d3d.com/group.dd5d169067b4da76.png');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 32vh;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 24px 0px 0px;
}
.eDruqT {
    color: rgb(244, 67, 54);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4px;
}
.kMRvJY {
    color: rgb(92, 92, 92);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 4px;
}
.jiNnzP {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin-top: 18px;
}
.jyjJIu {
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 14px;
}
.ljoKKN:nth-child(1) {
    order: 2;
}
.ljoKKN {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.iavbGS {
    border: 4px solid #ff004c;
}
.kHYfza * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
:where(.css-zl9ks2).ant-avatar-image {
    background: transparent;
}
.AZDY {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGkAAAB4CAYAAADxCNwEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJxSURBVHgB7dg7bhNRGEDh/44dKpBM4Uh0WYLLxGmcJbCCmBUAK3C8gmQHSVYAO8AFWHT2DkgHionk3kkuMw4PIYEIEYg5w/mqedzu6Or+MxENkhe7h3nRH0XDpGiA/G7QiQerw8gx/PzkZbTvPUkPJ8toAHyk/H57K9qtF+Vl77sXKc5idbWXHr09Czh0pHWgjeJV5LT1wwUNCVUEVF5sD2KjNftpoPWiqHbZLJ/3ewGGjJQvdp5GtModFJ1bLO9ESrP8YedZQOEi5YtyersujuJ3tQrs5Ic6k/LH3eNvE9ydHaXum+cBgoi0HrHvr6oJbhB/xjwurx5TBoraR/rlBHdXoMnva6T1tBStUfmkd8sDWX/PJHI+TZvTk+pmHenmME4HoZrJB6k7HafyG2JYjqjHoXq6THtFGWg/VF/tPErln+McqrMl9rfQf6RjJAAjARgJwEgARgIwEoCRAIwEYCQAIwEYCcBIAEYCMBKAkQCMBGAkACMBGAnASABGAjASgJEAjARgJAAjARgJwEgARgIwEoCRAIwEYCQAIwEYCcBIAEYCMBKAkQCMBGAkACMBGAnASABGAjASgJEAjARgJAAjARgJwEgARgIwEoCRAIwEYCQAIwEYCcBIAEYCMBKAkQCMBGAkACMBGAnASABGAjASgJEAjARgJAAjARgJwEgARgIwEoCRAIwEYCQAIwEYCcBIAEYCMBKAkQCMBGAkACMBGAnASABGAjASgJEAjARgJAAjARgJoIq0DNVaFWkeqrNJGSmNQ/WV82mRuq8nhqqp6zROm9OT9OU+n/eHkdJ+eTkI/UvVjDCvNs7NBor4BCchfEFKbjoGAAAAAElFTkSuQmCC);
    background-position: center top;
    background-size: cover;
    width: 100%;
    max-width: 145px;
    border-radius: 5px;
    padding: 20px 5px 10px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
}
:where(.css-zl9ks2).ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
:where(.css-zl9ks2)[class^="ant-avatar"], :where(.css-zl9ks2)[class*=" ant-avatar"] {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
}
:where(.css-zl9ks2).ant-avatar >img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.kHYfza * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.dwtYAJ {
    /* background-image: url(/static/media/board.f41572a….png); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: -5px;
    height: 90px;
    width: calc(100% + 20px);
}
.kHYfza * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.tFPOC {
    margin-top: 18px;
    width: 100%;
}
.ehvQnw {
    background-color: rgb(244, 67, 54);
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    padding: 9px 16px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    gap: 6px;
}
 </style>
