<template>
  <div
    style="
      background: #131730;
      background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8215.png');
      min-height: 100vh;
      height: auto;
      background-attachment: fixed;
      background-size: cover;
      overflow-y: scroll; 
    "
  >
    <div class="login-cont container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7 d-flex flex-column mt-2">
          <img
            class="mx-auto my-3"
            src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E7%BB%8428.8.png"
            alt=""
            style="width: auto; height: 12rem"
          />

          <!-- <div class="header-body text-center">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-8 px-5 pb-5">
                <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/b24d84d1-6400-4613-8cbf-06d927805b00/public" alt="">
              </div>
            </div>
          </div> -->


          <div class="card border-0 mb-0 auth-card" style="background: transparent !important">
            <div class="card-body px-0 py-lg-5">
              <div class="text-center mb-4">
                <div class="error text-white d-flex" v-if="error">
                  <span class="mx-auto">
                    {{ error }}
                  </span>
                </div>
              </div>

              <form role="form px-0 mx-0">
                <div class="form-group mb-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 px-0 mx-0 mx-auto"
                    style="margin-bottom: -3.7rem; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        >
                        <img style="width: 15px; height: auto;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8220.png" alt="">
                        <!-- <i class="fas fa-phone" style="font-weight: 600; font-size: 1.3rem; color: black"></i> -->
                      </span>
                    </div>
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Your Phone' : 'သင့် ဖုန်းနံပါတ်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%; color: #CFD6F1 !important; font-size: 1.1rem !important;"
                      type="text"
                      v-model="phone"
                      @keyup.enter="focusOnPasswordInputBox"
                    />
                  </div>
                  <img class="col-12" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E7%BB%842%E6%8B%B7%E8%B4%9D.m.png" style="height: 60px;" alt="">
                </div>

                <div class="form-group pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 px-0 mx-0 mx-auto"
                    style="margin-bottom: -3.7rem; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        >
                        <img style="width: 15px; height: auto;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8221.png" alt="">
                        <!-- <i class="fas fa-lock" style="font-weight: 600; font-size: 1.3rem; color: black"></i> -->
                        </span>
                        
                    </div>
                    
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Password' : 'လျှို့ဝှက်ကုတ်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%; color: #CFD6F1 !important; font-size: 1.1rem !important;"
                      type="password"
                      ref="password"
                      v-model="password"
                      @keyup.enter="loginWithPhone"
                    />
                    <i
                      class="far fa-eye showable-password"
                      v-if="isShow"
                      style="top: 10px;"
                      @click="showablePassword"
                    ></i>
                    <i
                      v-if="!isShow"
                      class="fas fa-eye-slash showable-password"
                      style="top: 10px;"
                      @click="showablePassword"
                    ></i>
                  </div>
                  <img class="col-12" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E7%BB%842%E6%8B%B7%E8%B4%9D.m.png" style="height: 60px;" alt="">
                </div>
                <div
                  class="custom-control custom-control-alternative custom-checkbox mx-auto col-11"
                >
                  <input
                    class="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label style="color: #9BC4FF; font-size: 1rem;" class="custom-control-label" for=" customCheckLogin">
                    ဒီအကောင့်ကို မှတ်ထားပေးပါ။
                  </label>
                </div>

                  <div class="d-flex justify-content-center pt-5">
                    <img style="width: 95%; height: 3.5rem !important;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E7%BB%8416%E6%8B%B7%E8%B4%9D.png" alt="">
                    <button
                      type="button"
                      class="btn"
                      style="position: absolute; color: #CFD6F1 !important; font-size: 1.1rem !important; width: 95% !important; height: 3.1rem !important; font-size: 1rem;"
                      @click="loginWithPhone"
                    >
                      <div v-if="!isSignin" class="text-nowrap">
                        {{
                          $store.state.language === 'en'
                            ? 'Sign In'
                            : 'အကောင့် ဝင်မည်။'
                        }}
                      </div>

                      <Loading
                        color="#CFD6F1"
                        v-if="isSignin"
                        size="18"
                        class="px-4 py-0"
                      />
                    </button>
                  </div>


                  <div class="text-center mt-2">
                    <div class="d-flex justify-content-center">
                      <img style="width: 95%; height: 3.5rem !important;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E7%BB%8416.b.png" alt="">
                      <button
                        type="button"
                        class="btn"
                        style="position: absolute; color: #CFD6F1; font-size: 1rem; width: 95% !important; height: 3.1rem !important;"
                        @click="$router.push('/auth/register')"
                      >
                        <div class="text-nowrap">
                          {{
                            $store.state.language === 'en'
                              ? 'Sign Up'
                              : 'အကောင့် အသစ်ဖွင့်မည်။'
                          }}
                        </div>
                      </button>
                    </div>

                  <!-- <h5 class="text-white mt-4 text-center">Contact To CS Team</h5> -->
                    <!-- <div class="d-flex justify-content-center mt-5">
                        <a href="https://m.me/1213173455499827" class="mx-2">
                            <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/d0ceb06f-4152-4a1e-a865-d1ae34570f00/public" alt="" style="width: 25px; height: 25px;"/>
                        </a>
                        <a href="viber://contact?number=%2B959968968600" class="mx-2">
                            <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/c573a737-9ffa-4399-fd21-e4ed1c443200/public" alt="" style="width: 26px; height: 26px;"/>
                        </a>
                        <a href="https://t.me/mdlcs1" class="mx-2">
                            <img src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4ee473a0-7938-4de1-db21-70213f0f6700/public" alt="" style="width: 25px; height: 25px;"/>
                        </a>
                    </div> -->


                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <!-- <div class="col-6 text-center">
                            <a
                                @click="$router.push('/auth/register')"
                                class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Create new account"
                                        : "အကောင့်အသစ်ဖွင့်မည်။"
                                }}</b></a
                            >
                        </div> -->

            <!-- <div class="col-12 text-center">
                            <a
                                data-toggle="modal"
                                data-target="#forgetPassword"
                                class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Forgot Password?"
                                        : "လျို့ဝှက်နံပါတ် မေ့နေပါသလား?"
                                }}</b></a
                            >
                        </div> -->
            <!-- === only amount === -->
            <div class="col-4">
              <div
                class="modal fade"
                id="forgetPassword"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal- modal-dialog-centered"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body p-5">
                      <b>
                        Please direct contact to customer service -
                        <span class="text-danger">09756477723</span>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      phone: null,
      password: null,
      error: false,
      isSignin: false,
      isShow: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    showablePassword() {
      if (this.isShow) {
        this.$refs.password.type = 'password';
      } else {
        this.$refs.password.type = 'text';
      }
      this.isShow = !this.isShow;
    },
    focusOnPasswordInputBox() {
      this.$refs.password.focus();
    },
    /**
     * login with phone
     */
    async loginWithPhone() {
      this.isSignin = true;
      this.error = null;
      if (!this.phone) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Required Phone Number'
            : 'Phone Number လိုအပ်ပါသည်။';
        this.isSignin = false;
        return false;
      }
      if (!this.password) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Required Password'
            : 'Password လိုအပ်ပါသည်။';
        this.isSignin = false;
        return false;
      }
      try {
          const res = await this.axios.post(
            '/auth/login',
            {
              phone: this.phone,
              password: this.password,
              roles: 'normal',
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          localStorage.setItem('phone', this.phone);
          localStorage.setItem('password', this.password);
          localStorage.setItem('token', res.data.token);

          const currentTime = new Date();
          localStorage.setItem(
            'tokenExpire',
            new Date(currentTime.getTime() + 60 * 60 * 1000)
          );
          await this.fetchUser();
          this.$router.push('/rule');
          // ..
          Toast.success(
            this.$store.state.language === 'en'
              ? 'Logged In!'
              : 'အကောင့်ဝင်ပြီးပါပြီ။'
          );
        // }
      } catch (err) {
        this.error = this.$store.state.language === 'en'
            ? 'Login Failed!'
            : 'အကောင့်ဝင်ခြင်း ကျရှုံးပါသည်။';
      }
      this.isSignin = false;
      return true;
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (authUser && authUser.is_verified && this.$store.state.authUser.roles === 'normal')
      this.$router.push('/home');
    const phoneNumber = localStorage.getItem('phone');
    const password = localStorage.getItem('password');
    if (phoneNumber && password) {
      this.phone = phoneNumber;
      this.password = password;
    }
  },
};
</script>

<style scoped>
/* Change placeholder text color for all input elements */
::placeholder {
            color: #2c2b2b; /* Light gray color */
        }

.login-cont {
  padding-top: 3rem;
}

.showable-password {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1000;
  color: #cfd6f1
}

.new-account-text {
  cursor: pointer;
}

.error {
  margin-bottom: 30px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}


.auth-card {
  background: #eeecec;
  /* color: black */
}

::placeholder {
  color: #cfd6f1;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #cfd6f1;
}
</style>
