<template>
    <div class="p-3" style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/bg.png'); min-height: 130vh; background-size: cover; background-attachment: fixed; overflow-y: scroll;">
        <div class="row align-items-center pl-4 pb-4">
            <div>
                <h3
                    class="mb-0"
                    style="color: #93a1c0"
                >
                    Holiday List
                </h3>
            </div>
        </div>

        <table class="col-12">
            <thead>
                <tr>
                    <th class="text-white">Date</th>
                    <th class="text-white">Name</th>
                    <!-- <th>Won Amount</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(close, index) in closedDayList" :key="index">
                    <td>{{close.date}}</td>
                    <td>{{close.name}}</td>
                    <!-- <td>{{Number(user.won).toLocaleString()}} MMK</td> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
  data() {
    return {
      closedDayList: []
    };
  },
  methods: {
    async closedDays(date) {
        try {
        const res = await axios.get('https://api.2dboss.com/api/lv/holiday_list');
        // console.log(res.data.data)
        this.closedDayList = res.data.data;
        } catch (error) {
        console.log(error);
        }
    },
  },
  mounted() {
    this.closedDays();
  },
};
</script>
<style scoped>

table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    th,
    td {
        padding: 10px !important;
        background-color: rgba(255,255,255,0.2);
        color: #fff;
    }

    th {
        text-align: left;
    }

    thead {
        th {
            background: #242946;
            color: black;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(95, 111, 180, 0.3);
            }
        }
        td {
            position: relative;
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(255,255,255,0.2);
                    z-index: -1;
                }
            }
        }
    }
</style>