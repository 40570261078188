<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8215.png'); min-height: 100vh; height: auto; background-size: cover;">
  
      <div class="col-12 account-container-wrapper d-flex py-1 my-2">
        <div class="col-lg-6 col-5 my-auto">
          <img :src="$store.state.authUser.gender === 'female' ? 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1b5a569c-c421-446c-86a8-98da438fbf00/public' : 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bc293e0-e453-442c-3dcd-a3572fece800/public'" style="width: auto; height: 100px; border-radius: 20%;" alt="">
        </div>

        <div class="col-lg-6 col-7 p-0">
          <div class="pt-2">
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px;"> 
                          {{
                              $store.state.language === "en"
                                  ? "Username"
                                  : "အမည်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{$store.state.authUser.name}} </p>
            </div>
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Balance"
                                  : "လက်ကျန်ငွေ"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">:
                <b>{{ Number($store.state.amount).toLocaleString() }}</b>
              {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
            </div>
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Phone"
                                  : "ဖုန်းနံပါတ်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
            </div>
            <div class="d-flex" v-if="$store.state.authUser.commissioner">
              <p class="css-17h40hp mb-1 text-nowrap" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Agent Referral"
                                  : "အေးဂျင့်ကုဒ်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.commissioner }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="justify-content-center cards-container-mobile mt-3">
  
        <div class="justify-content-center cards-container-mobile">
        <div
          class="col-xl-12 col-12 home-cards"
          @click="$router.push('user/card?t=skm')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2 py-3"
            style="width: 100%; height: auto; border-radius: 5px"
          >
            <div class="box__bg d-flex flex-column">
              <img
                src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/skmm2.jpg"
                alt=""
                style="width: 100%; height: auto; border-radius: 8px;"
              />
              <div class="pt-4">
                <h3 class="text-white">
                  {{ $store.state.language === 'en' ? 'Shan Koe Mee' : 'ရှမ်းကိုးမီး' }}
                </h3>
              </div>
            </div>
          </div>
        </div>
  
        <div
          class="col-xl-12 col-12 home-cards mt-1"
          @click="$router.push('user/card?t=bgy')"
        >
          <div
            class="d-flex justify-content-center align-items-center mb-2"
            style="width: 100%; height: auto; border-radius: 0.7rem;"
          >
            <div class="box__bg d-flex flex-column">
              <img
                src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/bgyii.jpg"
                alt=""
                style="width: 100%; height: auto; border-radius: 0.7rem;"
              />
            <div class="pt-4">
              <h3 class="text-white">
                {{ $store.state.language === 'en' ? 'Boo Gyi' : 'ဘူကြီး' }}
              </h3>
            </div>
            </div>
          </div>
        </div>

     </div>  
        
      </div>
      
    </div>
  </template>
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    methods: {
      ...mapActions(['fetchUser']),
    },
    async mounted() {
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      if (authUser && !authUser.is_verified) {
        this.$router.push('/auth/verify');
      }
    },
  };
  </script>
  <style scoped>

.box__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* height: 400px;
  width: 400px; */
  border: 3px solid #0000;
  border-radius: 12px;
  background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
        var(--angle),
        #070707,
        #687aff
      ) border-box;
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes bgRotate {
  100% {
    transform: rotate(1turn);
  }
}

  .css-38745a {
      margin: 0px;
      line-height: 1.5;
      font-size: 12px;
      /* font-family: NotoSansMyanmar; */
      font-weight: 500;
  }
  .css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500 !important;
  }
  .card {
    cursor: pointer;
    border-radius: 10px !important;
  }
  .main-image-wrapper img {
    width: 100%;
  }
  .video-container {
    height: 500px;
    width: 100%;
  }
  .notice-bar {
    margin-top: -40px;
    z-index: 1;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    background: rgba(255, 242, 0, 0.7);
  }
  .twod-threed-cards {
    height: 200px;
  }
  .live-number {
    font-weight: bold;
    font-size: 25px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .not-live-text {
    font-weight: bold;
    font-size: 24px;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
  }
  .card-body {
    padding: 0px !important;
  }
  .cards-container-mobile {
    display: none;
  }
  @keyframes live-number-animation {
    from {
      color: white;
    }
    to {
      color: red;
    }
  }
  
  /* @media (max-width: 768px) { */
    .middle-container {
      padding-top: 0.1rem !important;
    }
    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .video-container {
      height: 300px;
      width: 100%;
    }
    .main-wrapper {
      padding: 7px !important;
    }
    .notice-bar {
      margin-top: 0px;
      height: 25px;
      z-index: 1;
      font-size: 12px;
      color: #02133E;
      background: white;
      border-radius: 7px;
    }
    .live-number {
      font-weight: bold;
      font-size: 20px;
    }
    .live-result {
      font-weight: bolder;
      font-size: 37px;
    }
  
    .cards-container {
      display: none;
    }
    .cards-container-mobile {
      padding: 0px !important;
      /* margin-top: 5px !important; */
      display: flex;
      flex-wrap: wrap;
    }
    .home-cards {
      padding-left: 10px;
      padding-right: 10px;
    }
    .card-body {
      padding: 0px !important;
    }
    .not-live-text {
      font-size: 18px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .account-container-wrapper {
      width: auto;
      height: auto;
      border-radius: 10px;
      padding-left: 4px;
      padding-right: 4px;
      margin: 10px;
      color: white;
      background-color: rgba(255,255,255,0.2) !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .top-wrapper,
    .bottom-wrapper {
      padding: 10px;
      width: 100%;
      display: flex;
    }
    .top-right,
    .bottom-right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: end;
    }
    .top-left,
    .bottom-left {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: start;
    }
  /* } */
  </style>
  