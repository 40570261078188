<template>
  <div class="container-fluid d-flex flex-wrap pt-3 main-wrapper" style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/bg.png'); 
        height: 80vh; 
        min-height: 100vh;
        background-size: cover; 
        background-attachment: fixed; 
        overflow-y: scroll; ">
      <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
          <SideNav />
      </div> -->
      <div class="col-lg-12 col-12 p-0 pb-5">
          <div class="card">
              <div class="card-header pb-0 mb-2">
                  <div class="row align-items-center">
                      <div class="col-8">
                          <h3 class="mb-0 text-white">
                              {{
                                  $store.state.language === "en"
                                      ? "Withdraw"
                                      : "ငွေထုတ်"
                              }}
                          </h3>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <form>
                      <!-- <h6 class="heading-small text-muted text-white">
                          {{
                              $store.state.language === "en"
                                  ? "Account information"
                                  : "အကောင့်ဆိုင်ရာ အချက်အလက်များ"
                          }}
                      </h6> -->
                      <!-- <span class="heading-small text-muted text-muted">{{
                                  $store.state.language === "en"
                                      ? "User's Balance"
                                      : "လက်ကျန်ငွေ"
                              }} - </span>
                      <span>
                        <b class="text-danger">{{ Number($store.state.authUser.amount).toLocaleString() }}</b>
                        <span class="text-muted pl-2">{{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }}</span>
                      </span> -->

                      <div class="pl-lg-4">
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label
                                          class="form-control-label text-white"
                                          for="input-username"
                                          >{{
                                              $store.state.language === "en"
                                                  ? "Choose Deposit Type"
                                                  : "ငွေထုတ်ယူမူပုံစံ ရွေးချယ်ပါ"
                                          }}</label
                                      >
                                      <AccountSelect
                                          :data="selected_account"
                                          field="account_id"
                                          :accounts="accounts"
                                      />
                                      <span class="text-danger">{{
                                          errors.account
                                      }}</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="pl-lg-4">
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label
                                          class="form-control-label text-white"
                                          for="input-email"
                                          >{{
                                              $store.state.language === "en"
                                                  ? "Account Number"
                                                  : "ငွေလက်ခံမည့် အကောင့် နံပါတ်"
                                          }}</label
                                      >
                                      <input
                                          type="number"
                                          class="form-control"
                                          style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none;"
                                          placeholder="Account Number"
                                          v-model="
                                              withdrawData.account_number
                                          "
                                      />
                                      <span class="text-danger">{{
                                          errors.number
                                      }}</span>
                                  </div>
                              </div>

                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label
                                          class="form-control-label text-white"
                                          for="input-email"
                                          >{{
                                              $store.state.language === "en"
                                                  ? "Account Username"
                                                  : "ငွေလက်ခံမည့် အကောင့်နာမည်"
                                          }}</label
                                      >
                                      <input
                                          class="form-control"
                                          style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none;"
                                          placeholder="Account Number"
                                          v-model="
                                              withdrawData.account_name
                                          "
                                      />
                                      <span class="text-danger">{{
                                          errors.name
                                      }}</span>
                                  </div>
                              </div>

                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label class="form-control-label text-white">{{
                                          $store.state.language === "en"
                                              ? "Amount"
                                              : "ငွေပမာဏ"
                                      }}</label>
                                      <span class="text-danger text-xs">{{
                                          $store.state.language === "en"
                                              ? " ( Minimum amount 5,000 )"
                                              : " ( အနည်းဆုံး 5,000 )"
                                      }}</span>
                                      <input
                                          type="number"
                                          id="input-email"
                                          class="form-control"
                                          style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none;"
                                          placeholder="Amount"
                                          v-model="withdrawData.amount"
                                      />
                                      <span class="text-danger">{{
                                          errors.amount
                                      }}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="pl-lg-4">
                          <div class="form-group">
                              <label class="form-control-label text-white">{{
                                  $store.state.language === "en"
                                      ? "Remark"
                                      : "မှတ်ချက်"
                              }}</label>
                              <textarea
                                  rows="4"
                                  class="form-control"
                                  v-model="withdrawData.remark"
                                  style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; font-size: 14px; box-shadow: none;"
                                  placeholder="Remark"
                              ></textarea>
                          </div>
                          <div class="form-group">
                              <button
                                  type="button"
                                  class="btn btn-success text-dark"
                                  @click="sendWithdrawData"
                              >
                                  <div v-if="!isConfirm">
                                      {{
                                          $store.state.language === "en"
                                              ? "Confirm"
                                              : "အတည်ပြုမည်"
                                      }}
                                  </div>
                                  <Loading
                                      color="#ffffff"
                                      v-if="isConfirm"
                                      size="24"
                                      class="px-4 py-0"
                                  />
                              </button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
components: { AccountSelect, Loading },
data() {
  return {
    imageUploader: [],
    selected_account: {
      account_id: null,
    },
    accounts: [
      {
        name: 'KBZ pay',
        id: 'KBZ pay',
        link: 'https://cdn.gold549.com/build/assets/img/payments/kpay.png',
      },
      // {
      //   name: 'KBZ Mobile Banking',
      //   id: 'KBZ Mobile Banking',
      //   link: 'https://cdn.gold549.com/build/assets/img/payments/kbz.png',
      // },
      {
        name: 'Wave pay',
        id: 'Wave pay',
        link: 'https://cdn.gold549.com/build/assets/img/payments/wavepay.png',
      },
      // {
      //   name: 'CB Pay',
      //   id: 'CB Pay',
      //   link: 'https://cdn.gold549.com/build/assets/img/payments/cb_pay.png',
      // },
      // {
      //   name: 'CB Banking',
      //   id: 'CB Banking',
      //   link: 'https://cdn.gold549.com/build/assets/img/payments/cbbank.png',
      // },
      // {
      //   name: 'AYA Pay',
      //   id: 'AYA Pay',
      //   link: 'https://cdn.gold549.com/build/assets/img/payments/ayapay.png',
      // },
      // {
      //   name: 'AYA Banking',
      //   id: 'AYA Banking',
      //   link: 'https://cdn.gold549.com/build/assets/img/payments/aya.png',
      // },
    ],
    account: [],
    // imageUploader: [],
    withdrawData: {},
    errors: {},
    isConfirm: false,
  };
},
methods: {
  // async fetchAccounts() {
  //   try {
  //     const res = await axios.get('/accounts');
  //     this.accounts = res.data.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  ...mapActions(['fetchUser']),
  async sendWithdrawData() {
    try {
      const authUser = await this.fetchUser();
      this.errors = {};
      if (!this.selected_account.account_id) {
        this.errors.account = this.$store.state.language === 'en'
          ? 'Please pick account type'
          : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
        return false;
      }
      if (!this.withdrawData.account_number) {
        this.errors.number = this.$store.state.language === 'en'
          ? 'Please enter account number'
          : 'ငွေလက်ခံမည့် အကောင့်နံပါတ် ထည့်သွင်းပေးပါ။';
        return false;
      }
      if (!this.withdrawData.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏထည့်ပေးပါ။';
        return false;
      }
      if (this.withdrawData.amount < 5000) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 5000 MMK'
          : 'ငွေပမာဏအနည်းဆုံး 5000 ထည့်ပေးပါ။';
        return false;
      }
      if (!this.withdrawData.account_name) {
        this.errors.name = this.$store.state.language === 'en'
          ? 'Please enter payment account name'
          : 'ငွေလက်ခံမည့် အကောင့်နာမည် ထည့်သွင်းပေးပါ။';
        return false;
      }
      if (this.withdrawData.amount > parseInt(authUser.amount)) {
        const balanceError = this.$store.state.language === 'en'
          ? "You Don't Have Sufficient Balance"
          : 'အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။';
        Dialog.alert({
          message: balanceError,
          confirmButtonText:
                          this.$store.state.language === 'en'
                            ? 'Go Back'
                            : 'နောက်သို့ပြန်သွားမည်။',
        }).then(() => {
          // on close
        });
        return false;
      }
      if (this.isConfirm) {
        return false;
      }
      this.$store.commit(
        'setAmount',
        (this.$store.state.amount -= this.withdrawData.amount),
      );
      this.isConfirm = true;
      // if (this.imageUploader[0]) {
      //   this.withdrawData.transaction_image = this.imageUploader[0].content;
      // }
      this.withdrawData.type = this.selected_account.account_id;
      this.withdrawData.date = this.currentTime;
      const res = await axios.post('/withdraws', this.withdrawData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
      const resUser = await axios.post(
        `/admin/user/${this.$store.state.authUser.id}`,
        {
          amount: this.withdrawData.amount,
          type: 'withdraw',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
      );
      // this.imageUploader = [];
      this.withdrawData = {};
      this.selected_account.account_id = null;
      Toast.success('Success');
      this.isConfirm = false;
      this.fetchUser();
    } catch (error) {
      this.isConfirm = false;
      let errorMessage = 'ငွေထုတ်၍ မရသေးပါ။ Customer service သို့ဆက်သွယ်ပေးပါ။';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      Dialog.alert({
          message: errorMessage,
          confirmButtonText:
                          this.$store.state.language === 'en'
                            ? 'Go Back'
                            : 'နောက်သို့ပြန်သွားမည်။',
        })
    }
    return true;
  },
  createDateWithTimeZone() {
    const moment = require('moment-timezone');
    const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
    // Extract individual components from moment object
    const year = dateInTimeZone.year();
    const month = dateInTimeZone.month();
    const day = dateInTimeZone.date();
    const hour = dateInTimeZone.hour();
    const minute = dateInTimeZone.minute();
    const second = dateInTimeZone.second();
    const millisecond = dateInTimeZone.millisecond();

    // Create a new Date object with the extracted components
    const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
    return newDateObject;
  },
},
//   watch: {
//     'selected_account.account_id': function () {
//       this.account = this.accounts.filter(
//         (account) => account.id === this.selected_account.account_id,
//       );
//     },
//   },
computed: {
  currentTime() {
    return moment(this.createDateWithTimeZone()).format('lll');
  },
},
async mounted() {
  const authUser = await this.fetchUser();
  if (!authUser) {
    this.$router.push('/auth/login');
  }
  if (authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
},
};
</script>
<style scoped>
.image-container {
  width: 200px;
  height: 200px;
}
.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.number-container {
  border-radius: 5px;
  background: #e9ecef;
  justify-content: center;
}
.custom-file {
  height: auto;
}
@media (max-width: 768px) {
  .main-wrapper {
      padding: 7px !important;
  }
}
</style>
