<template>
    <div class="px-3 py-4" style="background: #131730;">
        <h3 class="text-center" style="color: #FAE63C">
            <!-- <i class="fas fa-book mr-2 my-1"></i> -->
                <!-- {{
                    $store.state.language === "en"
                        ? "Rules And Regulations By ManDaLarThu"
                        : "မန္တလာသူ မှထုတ်ပြန်သော စည်းကမ်းချက်များ စည်းကမ်းချက်များ"
                }} -->
                မန္တလာသူ မှထုတ်ပြန်သော <br> စည်းကမ်းချက်များ
            </h3>
        <ul style="color: #93A1C0">
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (1) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၁) </span>ဘော်ဒီများကိုအနည်းဆုံး (1000) ကျပ်မှ စတင်ပြီး ဆော့ကစားနိုင်ပါသည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (2) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၂) </span>ပွဲစဉ်များကို  ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်ကို 5% သာ ကောက်ခံပါသည်။<br> <b>အကောက် - </b><br>
                 <b class="ml-4">ပွဲကြီး - 5%</b> <br>
                 <b class="ml-4">ပွဲသေး - 5%</b>
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (3) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၃) </span>မောင်း အနည်း ဆုံး (500) ကျပ်မှ ကစားနိုင်သည်။ <br> <b> မောင်းအကောက် -</b> <br>
                    <b class="ml-4">2 သင်း - 15%</b> <br>
                    <!-- <b class="ml-4">3 သင်း - 8 သင်း > 20%</b> <br> -->
                    <b class="ml-4">3 သင်း - 11 သင်း > 20%</b> <br>
            </li>
            <li class="my-4">
                <span class="text-danger mr-3"> (၄) </span>အနိုင် အရှုံး သရေ(1x2)၊ ဂိုးခန့်မှန်းရလဒ်(focus)တို့ကို အနည်းဆုံး(၁၀၀၀)ကျပ်မှ စတင်ကစားနိုင်ပါသည်။ <br> <b>အကောက် - </b> <br>
                <b class="ml-4">1x2 - 5%</b> <br>
                <b class="ml-4">Focus - 5%</b>
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (6) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၅) </span>သက်မှတ်ချိန် တစ်ရက်တာအတွင်း ပွဲပျက်(သို့မဟုတ်) မကန်သောပွဲစဉ်များ ရလဒ်မထွက်သောပွဲစဉ်များအား အလျော်အစားမလုပ်ပဲ ထိုးငွေအား ပြန်ပေးပါမည်။
                (မှတ်ချက် - သက်မှတ်ပွဲစချိန်မှ 2 နာရီ အတွင်း မကန်သော အချိို့ပွဲစဉ်များအား ပွဲပျက်ဟု သတ်မှတ်နိုင်သည်။)
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (8) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၆) </span>အချိန်စောကန်သွားသော ပွဲစဉ်များအား မန္တလာသူ Application တွင်မူလအချိန်အတိုင်း အချိန်မပြောင်းပဲ ဖွင့်ထားပါက ပွဲပျက်သက်မှတ်ပါမည်။ အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (7) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၇) </span>ဂိုးရလဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting Company မှဂိုးရလဒ်ဖြင့် အလျော်အစားပြုလုပ်ပါမည် ( Ibet789 / Bet365 )
            </li>
            
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (9) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၈) </span>နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့် ပေါက်ကြေးလွန်စွာကွဲလွဲနေပါက Cancal match ပြုလုပ်ပါမည်။
            </li>

            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (10) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၉) </span>နည်းပညာချွတ်ယွင်း၍ အလျော်အစားမှားပြုလုပ်ထားသော ဘောင်ချာများအား နောက်တစ်နေ့ နေ့ခင်း 12 နာရီမတိုင်ခင် အကြောင်းကြားပေးထားသောဘောင်ချာများသာ တာဝန်ယူဖြေရှင်းပေးမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (11) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၁၀) </span>ပုံမှန်မဟုတ်သော ပွဲစဉ်များပါရှိပါက ၎င်းပွဲစဉ်အား အလျော်အစားမပြုပဲ Cancel Match ပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (12) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၁၁) </span>ဆော့ကစားခြင်းမရှိပဲ ငွေ အထုတ်/အသွင်း ပြုလုပ်သော အကောင့်များအား ငွေပြန်မအမ်းပဲ ပိတ်သိမ်းပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (13) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၁၂) </span>နည်းပညာအကူအညီဖြင့်သော်လည်းကောင်း၊ အခြားမရိုးသားသည့်နည်းလမ်းများကို အသုံးပြု၍ ကစားထားသည်များကို တွေ့ရှိရပါက ငွေပြန်ထုတ်ပေးခြင်းမရှိဘဲ ခြွင်းချက်မရှိ အကောင့်ပိတ်သိမ်းပါမည်။
            </li>
            <li class="my-4">
                <!-- <h3 class="text-danger m-0"> <span class="text-danger"> * </span> Rule (13) <span class="text-danger"> * </span></h3> -->
                <span class="text-danger mr-3"> (၁၃) </span>အငြင်းပွါးဖွယ်ရာ ကိစ္စများပေါ်ပေါက်လာပါက အများနှင့်နိူင်းယှဉ်သုံးသပ်ရသည့်အတွတ် မန္တလာသူ ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်သည်။
            </li>

            <li >
                <h5 class="text-center text-danger mt-4 mb-5 pb-3">
                    စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။
                </h5>
            </li>
            <li>
                <div class="d-flex justify-content-center my-4" style="position: fixed; bottom: 0; left: 0; right: 0;">
                    <button class="btn btn-danger" @click="logout">အတည်မပြုပါ</button>
                    <button class="btn btn-primary customzie-color" @click="toHome">အတည်ပြုပါသည်</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logoutUser']),
    async logout() {
          await this.logoutUser();
          this.$router.push('/auth/login');
    },
    toHome() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.customzie-color {
    background-color: #FAE63C !important;
    border-color: #FAE63C !important;
    color: black;
}
</style>
