<template>
    <div style="background-image: url('https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/%E5%9B%BE%E5%B1%8215.png'); min-height: 100vh; height: auto; background-size: cover;">
  
      <div class="col-12 account-container-wrapper d-flex py-1 my-2">
        <div class="col-lg-6 col-5 my-auto">
          <img :src="$store.state.authUser.gender === 'female' ? 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1b5a569c-c421-446c-86a8-98da438fbf00/public' : 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bc293e0-e453-442c-3dcd-a3572fece800/public'" style="width: auto; height: 100px; border-radius: 20%;" alt="">
        </div>

        <div class="col-lg-6 col-7 p-0">
          <div class="pt-2">
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px;"> 
                          {{
                              $store.state.language === "en"
                                  ? "Username"
                                  : "အမည်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{$store.state.authUser.name}} </p>
            </div>
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Balance"
                                  : "လက်ကျန်ငွေ"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">:
                <b>{{ Number($store.state.amount).toLocaleString() }}</b>
              {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
            </div>
            <div class="d-flex">
              <p class="css-17h40hp mb-1" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Phone"
                                  : "ဖုန်းနံပါတ်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
            </div>
            <div class="d-flex" v-if="$store.state.authUser.commissioner">
              <p class="css-17h40hp mb-1 text-nowrap" style="width: 85px">
                          {{
                              $store.state.language === "en"
                                  ? "Agent Referral"
                                  : "အေးဂျင့်ကုဒ်"
                          }}</p>
              <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.commissioner }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="justify-content-center cards-container-mobile">
  
        <div class="justify-content-center cards-container-mobile">
        <div
          class="col-xl-6 col-6 home-cards pr-1"
          @click="redirectRoute('maung-fb')"
        >
          <div
            class="card d-flex justify-content-center align-items-center mb-2 pt-2"
            style="width: 100%; height: auto; border-radius: 5px"
          >
            <div>
              <img
                src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f1a30048-e3a1-4451-cd1f-e87be75e0e00/public"
                alt=""
                style="width: auto; height: 100px"
              />
            </div>
            <!-- <div class="pt-1">
              <h4>
                  {{ $store.state.language === 'en' ? 'Body' : 'ဘော်ဒီ' }}
              </h4>
            </div> -->
          </div>
        </div>
  
        <div
          class="col-xl-6 col-6 home-cards pl-1"
          @click="redirectRoute('body-fb')"
        >
          <div
            class="card d-flex justify-content-center align-items-center mb-2 pt-2"
            style="width: 100%; height: auto; border-radius: 5px"
          >
            <div>
              <img
                src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/af3332ec-0827-4403-c087-3845777e5400/public"
                alt=""
                style="width: auto; height: 100px"
              />
            </div>
            <!-- <div class="pt-1">
              <h4>
                {{ $store.state.language === 'en' ? 'Maung' : 'မောင်း' }}
              </h4>
            </div> -->
          </div>
        </div>
  
        <div
          class="col-xl-6 col-6 home-cards pr-1"
          @click="redirectRoute('1x2-fb')"
        >
          <div
            class="card d-flex justify-content-center align-items-center mb-2 pt-2"
            style="width: 100%; height: auto; border-radius: 5px"
          >
            <div>
              <img
                src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/9da83930-d268-4ffa-e5c2-9d887387e500/public"
                alt=""
                style="width: auto; height: 100px"
              />
            </div>
            <!-- <div class="pt-1">
              <h4>
                  {{ $store.state.language === 'en' ? 'Body' : 'ဘော်ဒီ' }}
              </h4>
            </div> -->
          </div>
        </div>
  
        <div
          class="col-xl-6 col-6 home-cards pl-1"
          @click="redirectRoute('correct-score-fb')"
        >
          <div
            class="card d-flex justify-content-center align-items-center mb-2 pt-2"
            style="width: 100%; height: auto; border-radius: 5px"
          >
            <div>
              <img
                src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2520c8db-b88e-4afd-75f3-042f1f5c8400/public"
                alt=""
                style="width: auto; height: 100px"
              />
            </div>
          </div>
        </div>
     </div>  
        
      </div>
      
    </div>
  </template>
  <script>
  import { NoticeBar, Dialog, Toast } from 'vant';
  import { mapActions } from 'vuex';
  
  export default {
    components: { NoticeBar },
    data() {
      return {
        twoDdata: {},
        twoDHistories: {},
        threeDHistories: {},
        threedData: {},
        websiteInfo: {},
      };
    },
    methods: {
      ...mapActions(['logoutUser', 'fetchUser']),
      logout() {
        Dialog.confirm({
          title:
            this.$store.state.language === 'en'
              ? 'Are you sure to log out?'
              : 'ထွက်မှာ သေချာပါသလား?',
          cancelButtonText:
            this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
          confirmButtonText:
            this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
        })
          .then(async () => {
            await this.logoutUser();
            this.$router.push('/auth/login');
            Toast.success(
              this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
            );
          })
          .catch(() => {});
      },
      async redirectRoute(path) {
        try {
          if (path === 'tip') {
            const text =
              this.$store.state.language === 'en'
                ? 'This feature is not support yet.'
                : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
            Dialog.alert({
              message: text,
              confirmButtonText:
                this.$store.state.language === 'en' ? 'Okay' : 'Okay',
            }).then(() => {
              // on close
            });
          } else {
            this.$router.push(path);
          }
        } catch (error) {
          console.log(error);
        }
      },
      threedDrawDateFormat(date) {
        return moment(date).format('ll');
      },
      async fetchTwoDdata() {
        try {
          const res = await axios.get('/2d/data');
          this.isLive = res.data.data[0].isLive;
        } catch (error) {
          console.log(error);
        }
      },
      async fetchWebsiteData() {
        try {
          const res = await axios.get('/website-infos');
          this.websiteInfo = res.data.data;
        } catch (error) {
          console.log(error);
        }
      },
      async fetchThreedDdata() {
        try {
          const res = await axios.get('/3d/data');
          this.threedData = res.data.data[0];
        } catch (error) {
          console.log(error);
        }
      },
      async fetchTwoThreeDHistories() {
        try {
          const twoDres = await axios.get('/twod_history');
          const threeDres = await axios.get('/threed_history');
          this.twoDHistories = twoDres.data.data;
          this.threeDHistories = threeDres.data.data;
        } catch (error) {
          console.log(error);
        }
      },
    },
    async mounted() {
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      if (authUser && !authUser.is_verified) {
        this.$router.push('/auth/verify');
      }
      this.fetchWebsiteData();
      this.fetchUser();
      // this.fetchTwoDdata();
      // this.fetchThreedDdata();
      // this.fetchTwoThreeDHistories();
    },
  };
  </script>
  <style scoped>
  .css-38745a {
      margin: 0px;
      line-height: 1.5;
      font-size: 12px;
      /* font-family: NotoSansMyanmar; */
      font-weight: 500;
  }
  .css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500 !important;
  }
  .card {
    cursor: pointer;
    border-radius: 10px !important;
  }
  .main-image-wrapper img {
    width: 100%;
  }
  .video-container {
    height: 500px;
    width: 100%;
  }
  .notice-bar {
    margin-top: -40px;
    z-index: 1;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    background: rgba(255, 242, 0, 0.7);
  }
  .twod-threed-cards {
    height: 200px;
  }
  .live-number {
    font-weight: bold;
    font-size: 25px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .not-live-text {
    font-weight: bold;
    font-size: 24px;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
  }
  .card-body {
    padding: 0px !important;
  }
  .cards-container-mobile {
    display: none;
  }
  @keyframes live-number-animation {
    from {
      color: white;
    }
    to {
      color: red;
    }
  }
  
  /* @media (max-width: 768px) { */
    .middle-container {
      padding-top: 0.1rem !important;
    }
    hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .video-container {
      height: 300px;
      width: 100%;
    }
    .main-wrapper {
      padding: 7px !important;
    }
    .notice-bar {
      margin-top: 0px;
      height: 25px;
      z-index: 1;
      font-size: 12px;
      color: #02133E;
      background: white;
      border-radius: 7px;
    }
    .live-number {
      font-weight: bold;
      font-size: 20px;
    }
    .live-result {
      font-weight: bolder;
      font-size: 37px;
    }
  
    .cards-container {
      display: none;
    }
    .cards-container-mobile {
      padding: 0px !important;
      /* margin-top: 5px !important; */
      display: flex;
      flex-wrap: wrap;
    }
    .home-cards {
      padding-left: 10px;
      padding-right: 10px;
    }
    .card-body {
      padding: 0px !important;
    }
    .not-live-text {
      font-size: 18px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .account-container-wrapper {
      width: auto;
      height: auto;
      border-radius: 10px;
      padding-left: 4px;
      padding-right: 4px;
      margin: 10px;
      color: white;
      background-color: rgba(255,255,255,0.2) !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .top-wrapper,
    .bottom-wrapper {
      padding: 10px;
      width: 100%;
      display: flex;
    }
    .top-right,
    .bottom-right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: end;
    }
    .top-left,
    .bottom-left {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: start;
    }
  /* } */
  </style>
  