<template>
  <div>
    <Sticky>
      <nav class="navbar navbar-horizontal navbar-expand-lg" style="background: #1D1F26; border: none; border-bottom: 1px solid #323743;">
        <div class="container-fluid">
          <div
            class="navbar-brand text-dark"
            :class="
              $route.path !== '/home' ? 'd-flex justify-content-between' : ''
            "
          >
            <div
              class="d-flex flex-column banking-icons my-auto ml-1 mr-3"
              v-if="$route.path !== '/auth/login'"
            >
              <i
                class="fas fa-chevron-left mx-auto my-1 text-xl text-muted"
                @click=" $router.go(-1)"
                v-if="$route.path !== '/home'"
              ></i>
            </div>
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/logo.png"
              alt=""
              @click=" $router.push('/home')"
              style="width: 6rem; height: auto;"
              class="pt-2"
            />
            <!-- LOGO -->
          </div>
          <div class="d-flex">
            <div class="d-flex" v-if="$route.path !== '/auth/login' && $store.state.authUser">

              <img class="my-auto mr-2" :src="$store.state.authUser.gender === 'female' ? 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/profiles/Girl%20Profile%20.png' : 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/profiles/Boy%20Profile.png'" style="width: auto; height: 2.7rem; border-radius: 50%;" alt="">
              
              <span class="my-auto pr-3 d-flex flex-column" style="border-right: 1px solid #93a1c0;">
                <!-- <span class="text-sm text-uppercase" style="color: #ccc">
                  <img loading="lazy" class="coin" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/coin.png" alt="">
                </span> -->
                <span class="text-white text-md">
                  <b>ပင်မ ပိုက်ဆံအိတ်</b>
                </span>
                <span class="text-md" style="color: #EFDB01 !important;">
                  K {{ Number($store.state.authUser.amount).toLocaleString() }}
                  <img @click="showMainWallet()" class="ml-2" style="width: 1.2rem; margin-bottom: 0.1rem;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/Screenshot_2025-03-12_at_1.24.51_PM-removebg-preview.png" alt="">
                </span>
              </span>
            </div>

            <div class="d-flex" v-if="$route.path !== '/auth/login' && $store.state.authUser">

            <!-- <img class="my-auto mr-2" :src="$store.state.authUser.gender === 'female' ? 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/profiles/Girl%20Profile%20.png' : 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/profiles/Boy%20Profile.png'" style="width: auto; height: 2.7rem; border-radius: 50%;" alt=""> -->

            <span class="my-auto ml-3 pr-3 d-flex flex-column">
              <!-- <span class="text-sm text-uppercase" style="color: #ccc">
                <img loading="lazy" class="coin" src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/coin.png" alt="">
              </span> -->
              <span class="text-white text-md">
                <b>ဂိမ်း ပိုက်ဆံအိတ်</b>
              </span>
              <span class="text-md" style="color: #EFDB01 !important;">
                K {{ Number($store.state.authUser.game_balance).toLocaleString() }}
                <img @click="showGameWallet()" class="ml-2" style="width: 1.2rem; margin-bottom: 0.1rem;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/Screenshot_2025-03-12_at_1.24.51_PM-removebg-preview.png" alt="">
              </span>
            </span>
            </div>
            
            <!-- <div class="nav-link px-1 pl-3 mx-0 d-flex justify-content-between">
              <a href="#" @click="showTopImage()" style="position: relative">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/luckywin/icons/notification%20(1).png" style="width: 25px;">
              </a>
            </div>

            <van-popup v-model="showTop" round position="top" :style="{ height: '40%' }">
              <h3 class="pl-3 py-3">Latest Messages</h3>
                <div v-for="(list, index) in messages" :key="index" class="mx-4 py-3" style="border-bottom: 1px solid #CFCFCF" v-show="messages.length > 0">
                  <div class="d-flex justify-content-between">
                    <h4>{{ list.title }}</h4><h4>{{ timeFormat(list.created_at) }}</h4>
                  </div>
                  <span class="text-sm">{{ list.message }}</span>
                </div>
                <van-empty description="No Notifications Yet!" v-show="messages.length === 0"/>
            </van-popup> -->

            <!-- <div class="nav-link px-1 mx-0 d-flex justify-content-between">
              <a href="https://t.me/hulk333vip">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/icons/telegram%20(1).png" style="width: 25px;">
              </a>
            </div> -->

            <!-- Language -->
            </div> 
        </div>
      </nav>
    </Sticky>



    <div
                class="modal fade"
                id="balanceLimit"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content" style="background: rgba(0, 0, 0, 0.9);">
                        <div class="modal-body my-3 mx-2 pt-4 text-center">
                          <span class="text-white text-md">
                            သင့်ဂိမ်းပိုက်ဆံအိတ်လက်ကျန်ငွေသည် ၁၀၀၀ ကျပ်အောက်လျော့နည်းနေပါသဖြင့် ပင်မပိုက်ဆံအိတ်မှ ငွေလွှဲပေးပါ။<br>
                          </span>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="text-left text-muted d-flex justify-content-between">
                            <div class="ml-2">
                                <span class="mt-2" style="color: #46F49B">ပင်မ ပိုက်ဆံအိတ်  </span><br> <br>
                                <span style="color: #46F49B">ဂိမ်း ပိုက်ဆံအိတ်  </span><br>
                            </div>
                            <div class="mx-auto pr-5">
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i> <br> <br>
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i>
                            </div>
                            <div class="mr-3">
                                <span class="text-white">{{ Number($store.state.authUser.amount).toLocaleString() }} ကျပ်</span><br> <br>
                                <span class="text-white">
                                  <span class="text-success" v-if="$store.state.authUser.game_balance > 0">{{ Number($store.state.authUser.game_balance).toLocaleString() }}</span>
                                  <span class="text-danger" v-else>{{ Number($store.state.authUser.game_balance).toLocaleString() }}</span>
                                ကျပ်</span><br>
                            </div>
                          </div>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="d-flex flex-column">

                            <div class="px-3 pt-0 pb-4">
                              <button
                                type="button"
                                class="btn btn-success text-dark col-6"
                                @click="openMainToGame()"
                              >
                                  <div>
                                    ငွေလွှဲမည်
                                  </div>
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                class="modal fade"
                id="mainWallet"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content" style="background: rgba(0, 0, 0, 0.9);">
                        <div class="modal-body my-3 mx-2 pt-4 text-center">
                          <span class="text-white text-md">
                            ပင်မ ပိုက်ဆံအိတ်ကို အသုံးပြုနိုင်သောနေရာများ<br>
                          </span>
                          <div class="text-left text-muted pt-3 d-flex justify-content-between">
                            <div>
                                <span class="mt-2 text-white"><img style="width: 3rem;" src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/mdl-new-version/icons/Lottery.png" alt="">နှစ်လုံးထီ / သုံးလုံးထီ</span><br>
                                <span class="text-white"><img style="width: 3rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f8351d45-e73b-41d8-e32a-4a55ffd46900/public" alt="">ဘောလုံး</span><br>
                            </div>
                            <div class="mr-2 mt-2">
                                <!-- <span><img style="width: 3rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4a16e751-6e4e-4ff1-06de-509139d41200/public" alt="">ဖဲ ဂိမ်းများ</span><br> -->
                                <img class="ml-2 mr-2 pr-2" style="width: 2.1rem;" src="https://www.seinlucky123.com/_nuxt/img/transfer_icon_detail.f5061b4.svg" alt=""><span class=" text-white">ဂိမ်းပိုက်ဆံအိတ်သို့လွှဲခြင်း</span><br>
                                <div class="mt-3">
                                  <img class="ml-2 mr-2 pr-2" style="width: 2.1rem;" src="https://www.seinlucky123.com/_nuxt/img/transfer_icon_detail.f5061b4.svg" alt=""><span class=" text-white">ငွေသွင်း/ငွေထုတ်ပြုလုပ်ခြင်း</span><br>
                                </div>
                            </div>
                          </div>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="text-left text-muted d-flex justify-content-between">
                            <div class="ml-2">
                                <span class="mt-2" style="color: #46F49B">ပင်မ ပိုက်ဆံအိတ် </span><br> <br>
                                <span style="color: #46F49B">ဂိမ်း ပိုက်ဆံအိတ် </span><br>
                            </div>
                            <div class="mx-auto pr-5">
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i> <br> <br>
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i>
                            </div>
                            <div class="mr-3">
                                <span class="text-white">
                                  <span class="text-success" v-if="$store.state.authUser.amount > 0">{{ Number($store.state.authUser.amount).toLocaleString() }}</span>
                                  <span class="text-danger" v-else>{{ Number($store.state.authUser.amount).toLocaleString() }}</span> ကျပ်
                                </span><br> <br>
                                <span class="text-white">{{ Number($store.state.authUser.game_balance).toLocaleString() }} ကျပ်</span><br>
                            </div>
                          </div>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="d-flex flex-column">
                            <div class="d-flex mx-auto">
                              <div style="color: #EFDB01; margin-right: 7rem;">မှ</div>
                              <div style="color: #EFDB01; margin-left: 5rem;">သို့</div>
                            </div>
                            <div class="d-flex mt-3">
                              <div class="col-5">
                                <select style="width: 100% !important; height: 2.5rem; border-radius: 0.7rem; box-shadow: none; padding-left: 1rem;" v-model="wallet.from">
                                  <option value="Main">ပင်မ ပိုက်ဆံအိတ်</option>
                                  <option value="Game">ဂိမ်း ပိုက်ဆံအိတ်</option>
                                </select>
                              </div>
                              <div class="col-2 d-flex">
                                <i
                                    class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                                ></i>
                              </div>
                              <div class="col-5">
                                <select style="width: 100% !important; height: 2.5rem; border-radius: 0.7rem; box-shadow: none; padding-left: 1rem;" v-model="wallet.to">
                                  <option value="Main">ပင်မ ပိုက်ဆံအိတ်</option>
                                  <option value="Game">ဂိမ်း ပိုက်ဆံအိတ်</option>
                                </select>
                              </div>
                            </div>

                            <div class="px-3 py-4">
                              <input
                                  type="number"
                                  id="input-email"
                                  class="form-control mb-3"
                                  placeholder="Amount"
                                  v-model="wallet.amount"
                                  style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none; height: 2.5rem; width: 100%;"
                              />
                              <button
                                type="button"
                                class="btn btn-success text-dark col-4"
                                @click="!isConfirm ? transferBalance() : ''"
                              >
                                  <div v-if="!isConfirm">
                                      {{
                                          $store.state.language === "en"
                                              ? "Confirm"
                                              : "အတည်ပြုမည်"
                                      }}
                                  </div>

                                  <Loading
                                      color="#ffffff"
                                      v-if="isConfirm"
                                      size="24"
                                      class="px-4 py-0"
                                  />
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>



            <div
                class="modal fade"
                id="gameWallet"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content" style="background: rgba(0, 0, 0, 0.9);">
                        <div class="modal-body my-3 mx-2 pt-4 text-center">
                          <span class="text-white text-md">
                              ဂိမ်းပိုက်ဆံအိတ်ကို အသုံးပြုနိုင်သောနေရာများ<br>
                          </span>
                          <div class="text-left text-muted pt-3 d-flex justify-content-between">
                            <div>
                                <span class="mt-2 text-white"><img style="width: 3rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/77d739a7-33dc-49df-0764-0c307fb68200/public" alt="">စလော့ ဂိမ်းများ</span><br>
                                <span class="text-white"><img style="width: 3rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/37918220-37cf-4ddc-97de-2df2fbde8c00/public" alt="">ငါးပစ် ဂိမ်းများ</span><br>
                            </div>
                            <div class="mr-2">
                                <span class="text-white"><img style="width: 3rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4a16e751-6e4e-4ff1-06de-509139d41200/public" alt="">ဖဲ ဂိမ်းများ</span><br>
                                <img class="ml-2 mr-2 pr-2" style="width: 2.1rem;" src="https://www.seinlucky123.com/_nuxt/img/transfer_icon_detail.f5061b4.svg" alt=""><span class="text-white">ပိုက်ဆံ လွှဲပြောင်းခြင်း</span><br>
                            </div>
                          </div>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="text-left text-muted d-flex justify-content-between">
                            <div class="ml-2">
                                <span class="mt-2" style="color: #46F49B">ပင်မ ပိုက်ဆံအိတ်  </span><br> <br>
                                <span style="color: #46F49B">ဂိမ်း ပိုက်ဆံအိတ်  </span><br>
                            </div>
                            <div class="mx-auto pr-5">
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i> <br> <br>
                              <i
                                  class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                              ></i>
                            </div>
                            <div class="mr-3">
                                <span class="text-white">{{ Number($store.state.authUser.amount).toLocaleString() }} ကျပ်</span><br> <br>
                                <span class="text-white">
                                  <span class="text-success" v-if="$store.state.authUser.game_balance > 0">{{ Number($store.state.authUser.game_balance).toLocaleString() }}</span>
                                  <span class="text-danger" v-else>{{ Number($store.state.authUser.game_balance).toLocaleString() }}</span>
                                ကျပ်</span><br>
                            </div>
                          </div>
                          <hr style="border-top: 0.5px solid #8899AA; width: 50%;">
                          <div class="d-flex flex-column">
                            <div class="d-flex mx-auto">
                              <div style="color: #EFDB01; margin-right: 7rem;">မှ</div>
                              <div style="color: #EFDB01; margin-left: 5rem;">သို့</div>
                            </div>
                            <div class="d-flex mt-3">
                              <div class="col-5">
                                <select style="width: 100% !important; height: 2.5rem; border-radius: 0.7rem; box-shadow: none; padding-left: 1rem;" v-model="wallet.from">
                                  <option value="Main">ပင်မ ပိုက်ဆံအိတ်</option>
                                  <option value="Game">ဂိမ်း ပိုက်ဆံအိတ်</option>
                                </select>
                              </div>
                              <div class="col-2 d-flex">
                                <i
                                    class="my-auto mx-auto fas fa-long-arrow-alt-right text-white"
                                ></i>
                              </div>
                              <div class="col-5">
                                <select style="width: 100% !important; height: 2.5rem; border-radius: 0.7rem; box-shadow: none; padding-left: 1rem;" v-model="wallet.to">
                                  <option value="Main">ပင်မ ပိုက်ဆံအိတ်</option>
                                  <option value="Game">ဂိမ်း ပိုက်ဆံအိတ်</option>
                                </select>
                              </div>
                            </div>

                            <div class="px-3 py-4">
                              <input
                                  type="number"
                                  id="input-email"
                                  class="form-control mb-3"
                                  placeholder="Amount"
                                  v-model="wallet.amount"
                                  style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none; height: 2.5rem; width: 100%;"
                              />
                              <button
                                type="button"
                                class="btn btn-success text-dark col-4"
                                @click="!isConfirm ? transferBalance() : ''"
                              >
                                  <div v-if="!isConfirm">
                                      {{
                                          $store.state.language === "en"
                                              ? "Confirm"
                                              : "အတည်ပြုမည်"
                                      }}
                                  </div>

                                  <Loading
                                      color="#ffffff"
                                      v-if="isConfirm"
                                      size="24"
                                      class="px-4 py-0"
                                  />
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
  </div>
</template>
<script>
import { Toast, Dialog, Sticky, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Sticky, Loading },
  data() {
    return {
      messages: [],
      wallet: {
        to: 'Main',
        from: 'Game',
        amount: 0,
      },
      showTop: false,
      // imageLink: `https://ui-avatars.com/api/?name=${this.$store.state.authUser.email}&background=0D8ABC&color=fff`,
      isLogged: false,
      authUser: null,
      alreadyGranted: true,
      isConfirm: false,
    };
  },
  watch: {
    'wallet.to': function () {
      if(this.wallet.to === 'Main') {
        this.wallet.from = 'Game';
      } else {
        this.wallet.from = 'Main';
      }
    },
    'wallet.from': function () {
      if(this.wallet.from === 'Main') {
        this.wallet.to = 'Game';
      } else {
        this.wallet.to = 'Main';
      }
    },
  },
  methods: {
    openMainToGame() {
      $('#balanceLimit').modal('hide');
      $('#gameWallet').modal('show')
    },
    async transferBalance() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure?'
            : 'သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မလုပ်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          this.isConfirm = true;
          const res = await axios.post(
                            "/transferWallet",
                            this.wallet, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                                },
                            }
                        );

          Toast.success(
            'Success!'
          );
          await this.fetchUser();
          this.wallet.amount = 0;
          this.isConfirm = false;
        })
        .catch((error) => {
          this.isConfirm = false;
          let errorMessage = null;
          if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
            Dialog.alert({
              message: errorMessage,
              confirmButtonText:
                              this.$store.state.language === 'en'
                                ? 'Go Back'
                                : 'နောက်သို့ပြန်သွားမည်။',
            })
          }

         
        });
    },
    showGameWallet() {
      $('#gameWallet').modal('show');
      this.wallet.from = 'Main';
      this.wallet.amount = 0;
    },
    showMainWallet() {
      $('#mainWallet').modal('show');
      this.wallet.from = 'Game';
      this.wallet.amount = 0;
    },
    timeFormat(time) {
      return moment(time).startOf('hour').fromNow();
    },
    async getMessages() {
      try {
        const res = await axios.get('/messages');
        this.messages = res.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    showTopImage() {
      this.showTop = !this.showTop;
    },
    ...mapActions(['logoutUser', 'fetchUser']),
    askNotificationPermission() {
        Notification.requestPermission().then(async (permission) => {
          if(permission === 'granted') {
            this.alreadyGranted = false;
           alert(
              this.$store.state.language === 'en'
                ? 'Notification permission granted!'
                : 'အသိပေးချက်အတည်ပြုပြီးပြီ'
            );
          } else {
            alert(
              this.$store.state.language === 'en'
                ? 'Notification permission denied!'
                : 'အသိပေးချက်အတည်ပြုမပြီးပြီ'
            );
          }
        });
  },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          if(this.$route.path === '/home') {
            this.$router.go();
          } else {
            this.$router.push('/home');
            this.$router.go();
          }
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      this.$i18n.locale = language
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
  async mounted() {
    // this.getMessages();
    // if(window.Notification.permission === 'granted') {
    //   this.alreadyGranted = false;
    // }

    this.authUser = await this.fetchUser();
    if (this.authUser) {
      this.isLogged = true;
    }
  }
};
</script>
<style scoped>
/* CSS */
.login-btn {
  background: transparent;
  border: 1px solid #D02126;
  border-radius: 3px;
  padding: .3rem 1.5rem;
  color: #D02126;
}

.coin {
    height: 1.3rem;
    margin-top: -0.25rem;
    margin-right: 0.4rem;
    animation: flip-coin-49989627 2.5s linear infinite;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    transform-style: preserve-3d; /* Keeps the 3D effect */
}

/* Define the keyframes for the 3D flip animation */
@keyframes flip-coin-49989627 {
    0% {
        transform: rotateY(0deg); /* Front face */
    }
    50% {
        transform: rotateY(180deg); /* Back face */
    }
    100% {
        transform: rotateY(360deg); /* Front face again */
    }
}

.navbar {
  padding: 3px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* border-bottom: 2px solid black; */
}
.navbar-horizontal .navbar-brand img {
  height: 40px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand.brand-text {
  font-size: 20px;
  color: red;
  margin-left: 0px;
  margin-right: 10px;
}
.navbar-brand {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.bg-default {
  background-color: #02133E !important;
}
@media (max-width: 767.98px) {
  .navbar-brand {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
